<template>
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 v-if="state == 'future'" class="h4 mb-0">Upcoming Collections</h1>
                <h1 v-if="state == 'past'" class="h4 mb-0">Historical Collections</h1>
                <p v-if=hasPending class="small text-muted">We will send you an email when your pending collections are confirmed.</p>
            </div>
            
            <div>
                <router-link class="btn btn-primary" to="/collections/create">Request Collection</router-link>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col">
                <strong>Date</strong>
            </div>
            <div class="col text-center">
                <strong>Timeslot</strong>
            </div>
            <div class="col text-center">
                <strong>Collected by</strong>
            </div>
            <div class="col d-flex justify-content-end align-items-center">
                <strong>Status</strong>
            </div>
        </div>
        <ul v-if="collectionData && collectionData.data.length" class="order-list mb-4">
            <li v-for="collection of collectionData.data" v-bind:key="collection.id">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="">{{ $filters.formatDate(collection.date) }}</div>
                    </div>
                    <div class="col text-center">
                        <div class="">{{ $filters.formatTime(collection.time_start) }} - {{ $filters.formatTime(collection.time_stop) }}</div>
                    </div>
                    <div class="col text-center">
                        <div class="">{{ collection.type.description }}</div>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center">
                        <div class="text-center"><span class="badge" :class="statusClass(collection.status.id)">{{ collection.status.description }}</span></div>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="collectionData && !collectionData.data.length">
            No Collections
        </div>
        <Pagination
            v-if="collectionData && collectionData.data.length"
            v-bind:currentPage="currentPage"
            v-bind:lastPage="lastPage"
            :viewComponent="this.routeName"
        />
    </div>
</template>

<style scoped lang="scss">
    .order-list
    {
        list-style: none;
        padding: 0px;
        margin: 0px;

        > li
        {
            border-top: 1px solid #EEEEEE;
            padding: 10px 0px;

            strong
            {
                color: $swift-dark-grey;
            }
        }
    }
</style>

<script>
    import api from "@/services/api";
    import Pagination from '@/components/Pagination.vue';

    export default {
        components: {            
            Pagination,
        },
        data() {
            return {
                collectionData: null,
            }
        },
        props: {
            state: {
                default: 'future',
            },
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.collectionData !== null)
                {
                    return parseInt(this.collectionData.meta.last_page);
                }

                return null;
            },
            routeName () {
                switch(this.state)
                {
                    case 'past': return 'CollectionsHistoric';
                    default: return 'Collections';
                }
            },
            hasPending() {
                if (!this.collectionData) {
                    return false;
                }
                return this.collectionData.data.some(collection => collection.status.id == 0);
            }
        },
        methods: {
            moveUp() {
                const html = document.querySelector('html')
                var scrollBehavior = html.style.scrollBehavior;
                html.style.scrollBehavior = 'auto'
                window.scrollTo({
                    top: 0,
                });
                setTimeout(() => {
                    html.style.scrollBehavior = scrollBehavior;
                }, 100);
            },
            fetchCollections: function() {
                if(!this.$store.state.currentPracticeId)
                {
                    this.collectionData = null;
                    return;
                }

                this.moveUp();

                var params = {
                    practice_id: this.$store.state.currentPracticeId,
                    page: this.currentPage,
                };

                if(this.state == 'future')
                {
                    var todayDate = new Date();
                    params.from = todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1) < 10 ? '0' : '') + (todayDate.getMonth()+1) + '-' + (todayDate.getDate() < 10 ? '0' : '') + todayDate.getDate();
                }

                if(this.state == 'past')
                {
                    var yesterdayDate = new Date();
                    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
                    params.from = '2000-01-01';
                    params.to = yesterdayDate.getFullYear() + '-' + ((yesterdayDate.getMonth() + 1) < 10 ? '0' : '') + (yesterdayDate.getMonth()+1) + '-' + (yesterdayDate.getDate() < 10 ? '0' : '') + yesterdayDate.getDate();
                }

                api
                .get('/api/v1/collections', {
                    params: params,
                })
                .then((response) => {
                    this.collectionData = response.data;
                })
                .catch(() => {
                    console.log('Catch orders error');
                });
            },
            statusClass: function(status) {
                switch(status)
                {
                    case 1: return 'bg-success'; // Booked
                    case 3: return 'bg-success'; // Completed
                    case 4: return 'bg-danger'; // Rejected
                    case 5: return 'bg-danger'; // Cancelled
                    default: return 'bg-secondary';
                }
            },
        },
        mounted () {
            this.fetchCollections();
        },
        watch: {
            currentPage() {
                this.fetchCollections();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchCollections();
            },
            'state': function() {
                this.fetchCollections();
            }
        },
    }

</script>
